const COLLAPSED_DISPLAY_COUNT = 3;
const MAX_FLATTENED_COUNT = COLLAPSED_DISPLAY_COUNT + 1;

export function getCollapsedList<T>({
  collapsedCount = COLLAPSED_DISPLAY_COUNT,
  isCollapsed,
  items,
  maxFlattenedCount = MAX_FLATTENED_COUNT,
}: {
  items: T[];
  isCollapsed: boolean;
  collapsedCount?: number;
  maxFlattenedCount?: number;
}): {
  displayedItems: T[];
  collapseInfo: { collapsedCount: number; lastItem?: T };
} {
  if (isCollapsed && items.length > maxFlattenedCount) {
    return {
      displayedItems: [...items.slice(0, collapsedCount - 1)],
      collapseInfo: {
        collapsedCount: items.length - collapsedCount,
        lastItem: items[items.length - 1],
      },
    };
  } else {
    return {
      displayedItems: items,
      collapseInfo: {
        collapsedCount: 0,
      },
    };
  }
}
