import {
  CellId,
  CellType,
  HexVersionAtomicOperation,
  RichTextDocument,
  UPDATE_CODE_CELL,
  UPDATE_MARKDOWN_CELL,
  UPDATE_SQL_CELL,
  UPDATE_TEXT_CELL,
} from "@hex/common";
import { Literal, Static, Union } from "runtypes";

import { CellContentsMP } from "../../../redux/slices/hexVersionMPSlice.js";

import {
  replaceAllForRichtext,
  replaceAtRichTextPath,
} from "./richTextUtils.js";
export const REGEX_GLOBAL_ALL_MATCHES = "gmi";
export const REGEX_GLOBAL_CAPS_MATCHES = "gm";
/**
 * All of the types of searchable items that we can find in a project now.
 */
export const SearchItemTypeLiteral = Union(
  Literal("PROJECT_DESCRIPTION"),
  Literal("PROJECT_TITLE"),
  /**
   * A searchable term within a Monaco text cell or simple text. We use this so that we can index into the actual cell. This includes
   * any cell type with text and line numbers.
   */
  Literal("CELL_LINE"),
  /**
   * A searchable term within a rich text cell. This includes text cells.
   */
  Literal("CELL_RICH_TEXT_ELEMENT"),
  /**
   * An output variable of a cell.
   */
  Literal("CELL_OUTPUT"),
  /**
   * An input paramter for a cell. Not all cells will have input parameters, and some may have several.
   */
  Literal("CELL_INPUT"),
  /**
   * The cell's label.
   */
  Literal("CELL_LABEL"),
);

/**
 * global indicates return all matches, i indicates case insensitive.
 */
export const SEARCH_MAX_OCCURRENCES = 250;

type PROJECT_METADATA = "PROJECT_DESCRIPTION" | "PROJECT_TITLE";
export type MatchIndex = {
  startIndex: number;
  endIndex: number;
};

export type SearchableItemType = Static<typeof SearchItemTypeLiteral>;
type BaseSearchableItem = {
  /**
   * Used for headers.
   */
  cellLabel: string;
  /**
   * Used to group each set of matches into the search results.
   */
  groupById: PROJECT_METADATA | CellId;
  /**
   * The type of the searchable item, which can be used for rendering
   * or when deciding if we should replace the item with a new value
   * for find and replace.
   */
  type: SearchableItemType;
  /**
   * specific cell properties. All cells will have a type, label, and order, and some cells (text/sql/code)
   * will also have a line index.
   */
  lineIndex: number | null;
  /**
   * the initial source string that we are searching in.
   */
  lineSource: string;
  /**
   * For rich text elements, this is the searachable item's index path. Rich text has a tree strucutre represents as
   * nested arrays, so we can use the number array to find the path to the rich text element we care about.
   */
  richTextElementPath?: number[];
  /**
   * The searachable item's start and end index into the `source` string.
   */
  match?: MatchIndex;
};

type ReplaceContentsFunctionDef = (
  props: UpdateCellCallbackProps,
) => HexVersionAtomicOperation | null;

type ReplaceMapConfig = {
  [K in SearchableItemType]?: {
    updateCellContents: ReplaceContentsFunctionDef;
  };
};
type ReplaceableItemConfig = { [K in CellType]?: ReplaceMapConfig };

/**
 * Replaces all instances of a substring in a source string with a new substring.
 */
function replaceAllForSource({
  caseMatch,
  currentSubstring,
  exactWordMatch,
  replaceWith,
  sourceString,
}: {
  sourceString: string;
  currentSubstring: string;
  replaceWith: string;
  caseMatch: boolean;
  exactWordMatch: boolean;
}): string {
  const pattern = cleanSearchTerm(currentSubstring, exactWordMatch);
  // Create the regex flags
  const flags = caseMatch
    ? REGEX_GLOBAL_CAPS_MATCHES
    : REGEX_GLOBAL_ALL_MATCHES;

  // Create the regex
  const regex = new RegExp(pattern, flags);

  // Replace using the regex
  return sourceString.replace(regex, replaceWith);
}

export function getReplaceFunctionDefinition(
  item: SearchableItem,
): ReplaceContentsFunctionDef | null {
  if (item.cellType != null) {
    return (
      PROJECT_REPLACE_MULTIPLAYER_CONFIG_MAP[item.cellType]?.[item.type]
        ?.updateCellContents ?? null
    );
  }

  return null;
}

export function getKeyForReplaceItems(item: SearchableItem): string {
  return `${item.cellId}-${item.type}`;
}

export interface ReplaceItemOperationProps {
  cellContents: CellContentsMP;
  replaceString: string;
  replaceWith: string;
  caseMatch: boolean;
  exactWordMatch: boolean;
  cellItem: CellItem;
}

export interface UpdateSourceCallbackProps {
  cellId: CellId;
  cellContents: CellContentsMP;
  nextSource: string;
  nextRichText: null;
}

export interface UpdateRichtextCallbackProps {
  cellId: CellId;
  cellContents: CellContentsMP;
  nextSource: null;
  nextRichText: RichTextDocument;
}

type UpdateCellCallbackProps =
  | UpdateSourceCallbackProps
  | UpdateRichtextCallbackProps;

/**
 * Sentinel value that can be used to indicate a cell search item is not replaceable.
 */
export const SENTINEL_NO_MP_REPLACE_OPERATION = "NO_OP_MP_OPERATION";

/**
 * This is a mapping from replaceable cell types with the contents that can be replaced.
 * Note that the mapping replaceCallback only support 'Replace all' behavior right now.
 */
export const PROJECT_REPLACE_MULTIPLAYER_CONFIG_MAP: ReplaceableItemConfig = {
  CODE: {
    CELL_LINE: {
      updateCellContents: ({
        cellContents,
        cellId,
        nextSource,
      }: UpdateCellCallbackProps) => {
        return cellContents.__typename === "CodeCell" && nextSource != null
          ? UPDATE_CODE_CELL.create({
              key: "source",
              value: nextSource,
              cellId,
              codeCellId: cellContents.codeCellId,
            })
          : null;
      },
    },
  },
  SQL: {
    CELL_LINE: {
      updateCellContents: ({
        cellContents,
        cellId,
        nextSource,
      }: UpdateCellCallbackProps) => {
        return cellContents.__typename === "SqlCell" && nextSource != null
          ? UPDATE_SQL_CELL.create({
              key: "source",
              value: nextSource,
              cellId,
              sqlCellId: cellContents.sqlCellId,
            })
          : null;
      },
    },
  },
  MARKDOWN: {
    CELL_LINE: {
      updateCellContents: ({
        cellContents,
        cellId,
        nextSource,
      }: UpdateCellCallbackProps) => {
        return cellContents.__typename === "MarkdownCell" && nextSource != null
          ? UPDATE_MARKDOWN_CELL.create({
              key: "source",
              value: nextSource,
              cellId,
              markdownCellId: cellContents.markdownCellId,
            })
          : null;
      },
    },
  },
  TEXT: {
    CELL_RICH_TEXT_ELEMENT: {
      updateCellContents: ({
        cellContents,
        cellId,
        nextRichText,
      }: UpdateCellCallbackProps) => {
        return cellContents.__typename === "TextCell" && nextRichText != null
          ? UPDATE_TEXT_CELL.create({
              key: "richText",
              value: nextRichText,
              cellId,
              textCellId: cellContents.textCellId,
            })
          : null;
      },
    },
  },
};

export type CellItem = BaseSearchableItem & {
  type: SearchableItemType;
  cellId: CellId;
  cellType: CellType;
  cellOrder: string;
};

export type ProjectMetadataItem = BaseSearchableItem & {
  type: PROJECT_METADATA;
  cellId?: null;
  cellType?: null;
  cellOrder?: null;
};

/**
 * Util function to make sure that we are always splitting cell source lines in the same way.
 */
export function sourceToLines(source: string): string[] {
  return source.split(/\r?\n/);
}
export function linesToSource(lines: string[]): string {
  return lines.join("\n");
}
/**
 * Cleans the search term of any special characters that would be used in a regex.
 */
export function cleanSearchTerm(
  searchTerm: string,
  exactWordMatch: boolean,
): string {
  const cleanedTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  return exactWordMatch ? `\\b${cleanedTerm}\\b` : cleanedTerm;
}

/**
 * Util function to use the type that we use for grouping different project search results with
 * a specific header.
 */
export const getProjectSearchResultType = (
  item: SearchableItem,
): CellType | "ProjectMatch" => {
  if (item.cellType) {
    return item.cellType;
  }
  return "ProjectMatch";
};

export type SearchableItem = ProjectMetadataItem | CellItem;
/**
 * A searchableItem with a listOffset, which specifies where the item is in a search list.
 */
export type IndexedSearchItem = SearchableItem & {
  readonly listOffset: number;
};

/**
 * Generic utility function to either replace a cell source or rich item content.
 */
export function replaceSingleLineItem(
  item: CellItem,
  cellContents: CellContentsMP,
  replaceString: string,
): HexVersionAtomicOperation | null {
  if (getReplaceFunctionDefinition(item) == null) {
    return null;
  }

  if ("source" in cellContents) {
    return replaceSourceLineItem(item, cellContents, replaceString);
  } else if ("richText" in cellContents && item.richTextElementPath != null) {
    return replaceAtRichTextPath(item, cellContents, replaceString);
  }
  return null;
}

/*
 * This function will replace the source line item with the replaceString.
 * It is intended to only support raw string replacement for Monaco cells or raw text cells.
 */
export function replaceSourceLineItem(
  item: CellItem,
  cellContents: CellContentsMP,
  replaceString: string,
): HexVersionAtomicOperation | null {
  if (
    !getReplaceFunctionDefinition(item) ||
    item.lineIndex == null ||
    item.match == null ||
    !("source" in cellContents)
  ) {
    return null;
  }

  const sourceLines = sourceToLines(cellContents.source);
  if (sourceLines.length <= item.lineIndex) {
    return null;
  }
  const line = sourceLines[item.lineIndex];
  sourceLines[item.lineIndex] =
    line.substring(0, item.match.startIndex) +
    replaceString +
    line.substring(item.match.endIndex);

  const replaceItemConfig =
    PROJECT_REPLACE_MULTIPLAYER_CONFIG_MAP[item.cellType];

  if (replaceItemConfig && replaceItemConfig[item.type]) {
    const replaceItemConfigForType = replaceItemConfig[item.type];
    if (replaceItemConfigForType) {
      return (
        replaceItemConfigForType.updateCellContents?.({
          cellContents,
          cellId: item.cellId,
          nextSource: linesToSource(sourceLines),
          nextRichText: null,
        }) ?? null
      );
    }
  }
  return null;
}

export function replaceAllForItemSource({
  caseMatch,
  cellContents,
  cellItem,
  exactWordMatch,
  replaceString,
  replaceWith,
}: ReplaceItemOperationProps): HexVersionAtomicOperation | null {
  const replaceItemsUpdateFunction = getReplaceFunctionDefinition(cellItem);

  const sourceString = "source" in cellContents ? cellContents.source : null;
  const richTextDoc = "richText" in cellContents ? cellContents.richText : null;

  if (richTextDoc != null && replaceItemsUpdateFunction) {
    return replaceItemsUpdateFunction({
      cellContents,
      cellId: cellItem.cellId,
      nextRichText: replaceAllForRichtext({
        richTextDoc,
        replaceString,
        replaceWith,
        caseMatch,
        exactWordMatch,
      }),
      nextSource: null,
    });
  } else if (sourceString != null && replaceItemsUpdateFunction) {
    return replaceItemsUpdateFunction({
      cellContents,
      cellId: cellItem.cellId,
      nextSource: replaceAllForSource({
        sourceString,
        currentSubstring: replaceString,
        replaceWith,
        caseMatch,
        exactWordMatch,
      }),
      nextRichText: null,
    });
  }
  return null;
}

interface OccurenceIndexMatchConfig {
  caseMatch: boolean;
  exactWordMatch: boolean;
}

interface GetOccurenceIndexesArgs extends OccurenceIndexMatchConfig {
  searchableItem: string;
  searchTerm: string;
}

interface OccurenceIndexedResult {
  /**
   * The starting index of a searchable result.
   */
  start: number;
  /**
   * The ending index of a searchable result.
   * For now, this is just the length of the search term, but this needs to be updated to represent
   * a variable length so that we can support regex.
   */
  end: number;
}

interface GetOcurrencesResponse {
  results: OccurenceIndexedResult[];
  hasMore: boolean;
  error: boolean;
}

export const getOccurrenceIndexes = ({
  caseMatch,
  exactWordMatch,
  searchTerm,
  searchableItem,
}: GetOccurenceIndexesArgs): GetOcurrencesResponse => {
  if (searchTerm === "") return { results: [], hasMore: false, error: false };

  try {
    const cleanedSearchTerm = cleanSearchTerm(searchTerm, exactWordMatch);
    const flags = caseMatch
      ? REGEX_GLOBAL_CAPS_MATCHES
      : REGEX_GLOBAL_ALL_MATCHES;
    const regex = new RegExp(cleanedSearchTerm, flags);

    const allOccurrences: OccurenceIndexedResult[] = [];
    let match;

    while (
      (match = regex.exec(searchableItem)) != null &&
      allOccurrences.length < SEARCH_MAX_OCCURRENCES
    ) {
      if (regex.lastIndex === match.index) {
        regex.lastIndex++; // Avoid infinite loop for zero-length matches
      }
      allOccurrences.push({
        start: match.index,
        end: match.index + searchTerm.length,
      });
    }

    return {
      results: allOccurrences,
      hasMore: allOccurrences.length >= SEARCH_MAX_OCCURRENCES,
      error: false,
    };
  } catch (e) {
    console.error(e);
    return { results: [], hasMore: false, error: true };
  }
};
